import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const logos = [
    '/images/deepl.png',
    '/images/chatgtp.png',
    '/images/google.png',
    '/images/gemini.png',
];

const logoStyle = {
    width: '100%',
    height: '30px',
    margin: '0 32px',
    opacity: 0.7,
};

export default function LogoCollection() {
    return (
        <Box id="logoCollection" sx={{ py: 4 }}>
            <Typography
                component="p"
                variant="subtitle2"
                align="center"
                sx={{ color: 'text.secondary' }}
            >
                Trusted by the best companies
            </Typography>
            <Grid container sx={{ justifyContent: 'center', mt: 0.5, opacity: 0.6 }}>
                {logos.map((logo, index) => (
                    <Grid item key={index}>
                        <img
                            src={logo}
                            alt={`Company number ${index + 1}`}
                            style={logoStyle}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}
