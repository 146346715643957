import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import { Box, Button, Typography } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd'; // Import PersonAddIcon
import AppAppBar from './AppAppBar'; // Import AppAppBar
import Hero from './Hero'; // Adjusted import path for Hero
import LogoCollection from './LogoCollection'; // Adjusted import path for LogoCollection
import Highlights from './Highlights'; // Adjusted import path for Highlights
import Pricing from './Pricing'; // Adjusted import path for Pricing
import Features from './Features'; // Adjusted import path for Features
import Testimonials from './Testimonials'; // Adjusted import path for Testimonials
import Footer from './Footer'; // Adjusted import path for Footer
import getMPTheme from '../theme/getMPTheme'; // Adjusted path for the theme
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../slices/authSlice'; // Adjusted path for the Redux slice

export default function MarketingPage() {
    const [mode] = React.useState('light'); // Default to 'light'
    const MPTheme = createTheme(getMPTheme(mode)); // Create the theme using your theme logic
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);

    // Check authentication state
    React.useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (token || storedToken) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, [token]);

    // Handle actions
    const handleRegister = () => navigate('/register');
    const handleLogin = () => navigate('/login');
    const handleLogout = () => {
        dispatch(logout());
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        navigate('/');
    };
    const handleDashboard = () => navigate('/dashboard');

    return (
        <ThemeProvider theme={MPTheme}>
            <CssBaseline enableColorScheme />

            {/* Custom AppBar */}
            <AppAppBar
                isAuthenticated={isAuthenticated}
                handleLogin={handleLogin}
                handleLogout={handleLogout}
                handleRegister={handleRegister}
                handleDashboard={handleDashboard}
            />

            {/* Hero Section */}
            <Hero />

            {/* Custom Content Sections */}
            <div>
                <LogoCollection />
                <Features /> {/* Customize this component to match your existing features */}
                <Divider />
                <Testimonials />
                <Divider />
                <Highlights />
                <Divider />
                <Pricing />

                {/* Call to Action Section */}
                <Box
                    sx={{
                        backgroundColor: MPTheme.palette.primary.main,
                        color: '#fff',
                        padding: '50px',
                        textAlign: 'center',
                        marginTop: '50px',
                    }}
                >
                    <Typography variant="h4" gutterBottom>
                        Ready to Get Started?
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '20px' }}>
                        Sign up today and start translating with the best tools and translators available.
                    </Typography>
                    {!isAuthenticated && (
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            startIcon={<PersonAddIcon />}
                            onClick={handleRegister}
                        >
                            Get Started
                        </Button>
                    )}
                </Box>
            </div>

            {/* Footer */}
            <Footer />
        </ThemeProvider>
    );
}
