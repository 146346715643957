//import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { GoogleIcon, FacebookIcon, SitemarkIcon } from './CustomIcons';
import React, { useEffect } from 'react';


// Create a default light theme
const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
});

const Card = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    margin: 'auto',
    boxShadow:
        'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    [theme.breakpoints.up('sm')]: {
        width: '450px',
    },
}));

const SignUpContainer = styled(Stack)(({ theme }) => ({
    minHeight: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
    },
    backgroundImage:
        'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
    backgroundRepeat: 'no-repeat',
}));

export default function SignUp() {
    const [nameError, setNameError] = React.useState(false);
    const [nameErrorMessage, setNameErrorMessage] = React.useState('');
    const [emailError, setEmailError] = React.useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
    const [username, setUsername] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [error, setError] = React.useState('');
    const [teamId, setTeamId] = React.useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Capture the teamId from the URL using hash-based routing
        const hash = window.location.hash; // Extract everything after the #
        const queryParams = hash.split('?')[1]; // Split to get the query params part (after '?')

        if (queryParams) {
            const urlParams = new URLSearchParams(queryParams);
            const teamIdFromURL = urlParams.get('teamId');

            if (teamIdFromURL) {
                setTeamId(teamIdFromURL);  // Set the teamId state if present
                console.log('Captured teamId from URL:', teamIdFromURL);  // Debugging log
            } else {
                console.log('No teamId found in URL');  // Log when teamId is missing
            }
        } else {
            console.log('No query parameters found in URL');  // Log when no query parameters exist
        }
    }, []);

    const validateInputs = () => {
        if (!email || !/\S+@\S+\.\S+/.test(email)) {
            setEmailError(true);
            setEmailErrorMessage('Please enter a valid email address.');
            return false;
        }
        if (!password || password.length < 6) {
            setPasswordError(true);
            setPasswordErrorMessage('Password must be at least 6 characters long.');
            return false;
        }
        if (password !== confirmPassword) {
            setPasswordError(true);
            setPasswordErrorMessage('Passwords do not match.');
            return false;
        }
        if (!username) {
            setNameError(true);
            setNameErrorMessage('Name is required.');
            return false;
        }
        return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateInputs()) return;

        try {
            const registrationData = {
                username,
                email,
                password,
                teamId: teamId || null,
            };
            await axios.post('http://192.168.68.130:5002/users/register', registrationData);
            navigate('/login');
        } catch (error) {
            if (error.response) {
                setError(error.response.data.msg || 'An error occurred. Please try again.');
            } else {
                setError('Failed to connect to the server. Please check your internet connection.');
            }
        }
    };

    return (
        <ThemeProvider theme={lightTheme}>
            <CssBaseline />
            <SignUpContainer direction="column" justifyContent="space-between">
                <Card variant="outlined">
                    <SitemarkIcon />
                    <Typography component="h1" variant="h4">
                        Sign up
                    </Typography>
                    {error && <Typography color="error">{error}</Typography>}
                    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <FormControl>
                            <FormLabel htmlFor="username">Full name</FormLabel>
                            <TextField
                                autoComplete="name"
                                name="username"
                                required
                                fullWidth
                                id="username"
                                placeholder="Jon Snow"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                error={nameError}
                                helperText={nameErrorMessage}
                                color={nameError ? 'error' : 'primary'}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                placeholder="your@email.com"
                                name="email"
                                autoComplete="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                variant="outlined"
                                error={emailError}
                                helperText={emailErrorMessage}
                                color={emailError ? 'error' : 'primary'}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="password">Password</FormLabel>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                placeholder="••••••"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                variant="outlined"
                                error={passwordError}
                                helperText={passwordErrorMessage}
                                color={passwordError ? 'error' : 'primary'}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel>
                            <TextField
                                required
                                fullWidth
                                name="confirmPassword"
                                placeholder="••••••"
                                type="password"
                                id="confirmPassword"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                variant="outlined"
                                error={passwordError}
                                helperText={passwordErrorMessage}
                                color={passwordError ? 'error' : 'primary'}
                            />
                        </FormControl>
                        <FormControlLabel
                            control={<Checkbox value="allowExtraEmails" color="primary" />}
                            label="I want to receive updates via email."
                        />
                        <Button type="submit" fullWidth variant="contained">
                            Sign up
                        </Button>
                        <Typography sx={{ textAlign: 'center' }}>
                            Already have an account?{' '}
                            <Link href="/login" variant="body2">
                                Sign in
                            </Link>
                        </Typography>
                    </Box>
                    <Divider sx={{ my: 2 }}>or continue with</Divider>
                    <Stack direction="row" justifyContent="center" spacing={2}>
                        <Button
                            color="inherit"
                            variant="outlined"
                            startIcon={<GoogleIcon />}
                            href="http://localhost:8080/auth/google"
                        >
                            Google
                        </Button>
                        <Button
                            color="inherit"
                            variant="outlined"
                            startIcon={<FacebookIcon />}
                            href="http://localhost:8080/auth/facebook"
                        >
                            Facebook
                        </Button>
                    </Stack>
                </Card>
            </SignUpContainer>
        </ThemeProvider>
    );
}
