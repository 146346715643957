import React, { useEffect } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux'; // Import Redux hooks
import store from './store';
import ProfilePage from './components/ProfilePage'; // Import profile page
import HomePage from './components/HomePage';
import RegistrationForm from './components/SignUp';
import LoginPage from './components/SignInSide'; //LoginPage
import TranslationDashboard from './components/TranslationDashboard'; // Updated Translation Dashboard
import EditProject from './components/EditProject';  // Import edit project page
import TranslateProject from './components/TranslateProject';  // Import translate project page
import TranslationProjects from './components/TranslationProjects';
import NewTranslation from './components/NewTranslation'; // Import new translation
import LanguageSettings from './components/LanguageSettings'; // Import Language Settings page
import AccountSettings from './components/AccountSettings'; // Import Account Settings page
import DashboardHome from './components/DashboardHome'; // Import DashboardHome page
import { loginSuccess } from './slices/authSlice';  // Import loginSuccess action
import Team from './components/TeamPage';


// Create a ProtectedRoute component to check if the user is authenticated
const ProtectedRoute = ({ element: Component }) => {
    const { isAuthenticated } = useSelector((state) => state.auth);

    // If the user is authenticated, show the component, otherwise redirect to login
    return isAuthenticated ? <Component /> : <LoginPage />;
};

const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        // Check if token exists in localStorage on app load
        const token = localStorage.getItem('token');
        if (token) {
            // Dispatch the token to Redux to set authentication
            dispatch(loginSuccess({ token }));
        }
    }, [dispatch]);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/register" element={<RegistrationForm />} />
                <Route path="/login" element={<LoginPage />} />
               
                {/* Protect the dashboard and its nested routes */}
                <Route path="/dashboard/*" element={<ProtectedRoute element={TranslationDashboard} />}>
                    <Route path="" element={<DashboardHome />} /> {/* Default route to Dashboard Home */}
                    <Route path="projects" element={<TranslationProjects />} />
                    <Route path="new-translation" element={<NewTranslation />} />
                    <Route path="projects/:id/edit" element={<EditProject />} />
                    <Route path="projects/:id/translate" element={<TranslateProject />} />
                    <Route path="language-settings" element={<LanguageSettings />} />
                    <Route path="account-settings" element={<AccountSettings />} />
                    <Route path="profile" element={<ProfilePage />} />
                    <Route path="team" element={<Team />} />
                </Route>
            </Routes>
        </Router>
    );
};

// Wrap App in Redux Provider
const RootApp = () => (
    <Provider store={store}>
        <App />
    </Provider>
);

export default RootApp;
