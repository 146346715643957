import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Container, Typography, Grid, TextField, Box, MenuItem, IconButton, LinearProgress, Button, Select, FormControl, InputLabel, Pagination, InputAdornment, Checkbox, FormControlLabel } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Flag from 'react-world-flags';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UndoIcon from '@mui/icons-material/Undo';
import { jwtDecode } from "jwt-decode";
import io from 'socket.io-client';
import SendIcon from '@mui/icons-material/Send';

const TranslateProject = () => {
    const { id } = useParams();
    const [originalSections, setOriginalSections] = useState([]);
    const [translatedSections, setTranslatedSections] = useState([]);
    const [approvedSections, setApprovedSections] = useState([]);
    const [sourceLanguage, setSourceLanguage] = useState('');
    const [targetLanguage, setTargetLanguage] = useState('');
    const [overallProgress, setOverallProgress] = useState(0);
    const [pageProgress, setPageProgress] = useState(0);
    const [wordCount, setWordCount] = useState(0);
    const [charCount, setCharCount] = useState(0);
    const [translationService, setTranslationService] = useState('');
    const [teamMembers, setTeamMembers] = useState([]);
    const [excludeDates, setExcludeDates] = useState(false);
    const [excludeNumbers, setExcludeNumbers] = useState(false);
    const [excludeSymbols, setExcludeSymbols] = useState(false);
    const [projectCreator, setProjectCreator] = useState(''); 
    const [socket, setSocket] = useState(null);
    const [message, setMessage] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const token = useSelector((state) => state.auth.token);
    const [assignedTo, setAssignedTo] = useState(null);
    const chatBoxRef = useRef(null); // Create a ref to the chat box element
    const audioRef = useRef(new Audio('/assets/sounds/notification.mp3'));
    const [useTranslationMemory, setUseTranslationMemory] = useState(false);  // State for translation memory toggle



    let currentUserId;
    if (token) {
        const decodedToken = jwtDecode(token);
        currentUserId = decodedToken.id;
    }

    const [currentPage, setCurrentPage] = useState(1);
    const sectionsPerPage = 50;
    const indexOfLastSection = currentPage * sectionsPerPage;
    const indexOfFirstSection = indexOfLastSection - sectionsPerPage;

    const isDate = (text) => /\b\d{1,2}[\/\-]\d{1,2}[\/\-]\d{2,4}\b/.test(text);
    const isNumber = (text) => /^[0-9]+$/.test(text);
    const isSymbol = (text) => /^[!@#\$%\^\&*\)\(+=._-]+$/.test(text);

    const filteredSections = originalSections.filter(section => {
        if (excludeDates && isDate(section)) return false;
        if (excludeNumbers && isNumber(section)) return false;
        if (excludeSymbols && isSymbol(section)) return false;
        return true;
    });

    const currentSections = filteredSections.slice(indexOfFirstSection, indexOfLastSection);

    const getCountryCodeForLanguage = (languageCode) => {
        const languageToCountryMap = {
            EN: 'GB',
            ES: 'ES',
            FR: 'FR',
            DE: 'DE',
            ZH: 'CN',
            JA: 'JP',
            IT: 'IT'
        };
        return languageToCountryMap[languageCode] || 'UN';
    };

    const calculateCounts = useCallback((sections) => {
        let totalWords = 0;
        let totalChars = 0;
        sections.forEach((section) => {
            const words = section.split(/\s+/).filter(Boolean).length;
            totalWords += words;
            totalChars += section.length;
        });
        setWordCount(totalWords);
        setCharCount(totalChars);
    }, []);

    const calculateOverallProgress = useCallback((approvedSections) => {
        const totalSections = originalSections.length;
        const approvedCount = approvedSections.length;
        const newProgress = totalSections > 0 ? (approvedCount / totalSections) * 100 : 0;
        setOverallProgress(newProgress);
    }, [originalSections.length]);

    const calculatePageProgress = useCallback((sections, approvedSections) => {
        const approvedOnPage = sections.filter((_, index) =>
            approvedSections.includes(index + indexOfFirstSection)
        ).length;
        const pageProgress = sections.length > 0 ? (approvedOnPage / sections.length) * 100 : 0;
        setPageProgress(pageProgress);
    }, [indexOfFirstSection]);

    const scrollToBottom = () => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight; // Scroll to the bottom
        }
    };

    useEffect(() => {
        scrollToBottom(); // Scroll to the bottom when messages change
    }, [message]); // Trigger whenever `message` state changes

    useEffect(() => {
        const fetchTeamMembers = async () => {
            try {
                const response = await axios.get('/api/team', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setTeamMembers(response.data);
            } catch (err) {
                console.error('Error fetching team members:', err);
            }
        };
        fetchTeamMembers();
    }, [token]);

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const response = await axios.get(`/api/projects/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const { project } = response.data;

                setOriginalSections(project.sections);
                setTranslatedSections(project.translatedSections || []);
                setApprovedSections(project.approvedSections || []);
                setSourceLanguage(project.sourceLanguage);
                setTargetLanguage(project.targetLanguage);
                setProjectCreator(project.createdBy);
                setAssignedTo(project.assignedTo);

                calculateCounts(project.sections);
                calculateOverallProgress(project.approvedSections || []);
                calculatePageProgress(
                    project.sections.slice(indexOfFirstSection, indexOfLastSection),
                    project.approvedSections || []
                );
            } catch (err) {
                console.error('Error fetching project:', err);
            }
        };

        fetchProject();
    }, [
        id,
        token,
        currentPage,
        indexOfFirstSection,
        indexOfLastSection,
        calculateCounts,
        calculateOverallProgress,
        calculatePageProgress,
    ]);

    // Initialize the socket connection
    useEffect(() => {
        // Initialize the socket connection on component mount
        const newSocket = io('http://192.168.68.130:5002');  // Backend server URL
        setSocket(newSocket);

        // Fetch chat messages when the component loads
        const fetchMessages = async () => {
            try {
                const response = await axios.get(`/api/chat/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setMessage(response.data);  // Set fetched messages in state
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };

        fetchMessages();

        // Listen for incoming messages
        newSocket.on('receiveMessage', (data) => {
            console.log("Message received:", data);  // Debugging log
            setMessage((prevMessages) => [...prevMessages, data]);
        });

        // Clean up the socket connection on component unmount
        return () => {
            newSocket.close();
        };
    }, []);  // Empty dependency array ensures this runs only on mount/unmount

    // Join the chat room after currentUserId is available
    useEffect(() => {
        if (socket && currentUserId) {
            socket.emit('join', currentUserId);
            console.log(`User ${currentUserId} joined the chat room`);
        }
    }, [socket, currentUserId]);  // Run when both socket and userId are ready


    // Send message handler
    const handleSendMessage = () => {
        if (newMessage.trim()) {
            if (socket) {
                // Log the type and value of assignedTo and projectCreator for debugging
                console.log('AssignedTo:', assignedTo);  // Should be a string
                console.log('ProjectCreator:', typeof projectCreator, projectCreator);  // Should be an object

                // Extract the _id from projectCreator if it's an object
                const projectCreatorId = projectCreator && projectCreator._id ? projectCreator._id : projectCreator;

                // Since assignedTo is a string, compare directly
                const receiverId = currentUserId === assignedTo
                    ? projectCreatorId  // If current user is assignedTo, send to projectCreator
                    : assignedTo;  // Otherwise, send to assignedTo

                // Log the senderId and receiverId for debugging
                console.log(`Sending message from ${currentUserId} to ${receiverId}`);

                // Ensure receiverId is valid
                if (!receiverId) {
                    console.error('Invalid receiverId:', receiverId);
                    return;
                }

                // Prepare the message data
                const messageData = {
                    projectId: id,  // Project ID from params
                    senderId: { _id: currentUserId, avatarUrl: currentUserId.avatarUrl },  // Sender is the current user
                    receiverId,  // Correct receiver (project creator or assigned translator)
                    message: newMessage,
                };

                // Emit the message to the server
                socket.emit('sendMessage', messageData);

                // Add the message to local state for immediate feedback
                //setMessage([...message, messageData]);

                // Clear the input field after sending the message
                setNewMessage('');
            } else {
                console.error('Socket is not connected yet.');
            }
        }
    };

    const toggleApproval = async (index) => {
        const globalIndex = index + indexOfFirstSection;
        const isApproved = approvedSections.includes(globalIndex);
        const updatedApproved = isApproved
            ? approvedSections.filter((i) => i !== globalIndex)
            : [...approvedSections, globalIndex];

        setApprovedSections(updatedApproved);
        calculateOverallProgress(updatedApproved);
        calculatePageProgress(currentSections, updatedApproved);

        try {
            await axios.put(`/api/projects/${id}/toggle-approval`, {
                sectionIndex: globalIndex,
                approved: !isApproved,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        } catch (err) {
            console.error('Error saving toggle state:', err);
        }
    };

    const handleSaveTranslation = async () => {
        try {
            // Iterate over each section to save manually updated translations to the translation memory
            if (useTranslationMemory) {
                for (let index = 0; index < originalSections.length; index++) {
                    const originalText = originalSections[index];
                    const translatedText = translatedSections[index];

                    // Only save to translation memory if there is a translation for the section
                    if (translatedText) {
                        try {
                            await axios.post('/api/translation-memory/save', {
                                originalText,
                                translatedText,
                                sourceLanguage,
                                targetLanguage,
                                userId: currentUserId,
                            }, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            });

                            console.log(`Translation memory saved for section ${index}`);
                        } catch (err) {
                            console.error(`Error saving section ${index} to translation memory:`, err);
                        }
                    }
                }
            }

            // Proceed with saving the project translations
            await axios.put(`/api/projects/${id}/translate`, {
                translatedSections,
                approvedSections,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            alert('Translation saved successfully!');
        } catch (err) {
            console.error('Error saving translation:', err);
            alert('Error saving translation.');
        }
    };


    const handleTranslate = async (index) => {
        const originalText = originalSections[index + indexOfFirstSection];

        if (!translationService) {
            alert('Please select a translation service.');
            return;
        }

        // Check the translation memory if enabled
        if (useTranslationMemory) {
            try {
                const cachedTranslation = await axios.post('/api/translation-memory/check', {
                    userId: currentUserId,  // Include userId in the request
                    originalText,
                    sourceLanguage,
                    targetLanguage,
                });

                // If a cached translation exists, use it
                if (cachedTranslation.data.translatedText) {
                    console.log('Translation from memory');
                    handleTranslationChange(index, cachedTranslation.data.translatedText);
                    return;  // Skip API call if found in memory
                }
            } catch (err) {
                console.error('Error checking translation memory:', err);
            }
        }

        // If no cached translation is found, proceed with API translation
        if (!translatedSections[index + indexOfFirstSection]) {
            try {
                const response = await axios.post(`/api/translate`, {
                    text: originalText,
                    sourceLanguage,
                    targetLanguage,
                    service: translationService,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const translatedText = response.data.translatedText;
                handleTranslationChange(index, translatedText);

                // Save the translation in memory if enabled
                if (useTranslationMemory) {
                    try {
                        await axios.post('/api/translation-memory/save', {
                            userId: currentUserId,  // Include userId in the request
                            originalText,
                            translatedText,
                            sourceLanguage,
                            targetLanguage,
                        });
                        console.log('Translation saved to memory');
                    } catch (err) {
                        console.error('Error saving translation to memory:', err);
                    }
                }

            } catch (err) {
                console.error('Error translating text:', err);
                alert('Error translating text.');
            }
        }
    };

    const handleTranslationChange = async (index, value) => {
        const originalText = originalSections[index + indexOfFirstSection];
        const translatedText = value;

        // Update the translated sections with the new value
        const updatedTranslations = [...translatedSections];
        updatedTranslations[index + indexOfFirstSection] = value;
        setTranslatedSections(updatedTranslations);

        // Check if translation memory is enabled and save the manually updated translation
        if (useTranslationMemory) {
            try {
                // Save the manually updated translation to the translation memory with userId
                await axios.post('/api/translation-memory/save', {
                    userId: currentUserId,  // Pass the current userId
                    originalText,
                    translatedText,
                    sourceLanguage,
                    targetLanguage,
                });

                console.log(`Manually updated translation saved to memory for text: "${originalText}"`);
            } catch (err) {
                console.error('Error saving manually updated translation to memory:', err);
            }
        }
    };


    const handleAssignTranslator = async (translatorId) => {
        try {
            if (!translatorId) {
                alert('Please select a translator.');
                return;
            }

            // If 'unassign' is selected, it will unassign the translator
            const isUnassign = translatorId === 'unassign';

            await axios.put(`/api/projects/${id}/assign-translator`, {
                translatorId: isUnassign ? null : translatorId, // Set to null if unassigning
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            alert(isUnassign ? 'Translator unassigned successfully!' : 'Translator updated successfully!');

            // If unassigning, update the state to reflect that no translator is assigned
            setAssignedTo(isUnassign ? null : translatorId);

        } catch (err) {
            console.error('Error assigning translator:', err);
            alert('Error assigning translator.');
        }
    };


    const handleTranslatorChange = (e) => {
        const selectedTranslatorId = e.target.value;
        handleAssignTranslator(selectedTranslatorId);
    };

    const handleAutoTranslate = async () => {
        if (!translationService) {
            alert('Please select a translation service.');
            return;
        }

        for (let index = 0; index < currentSections.length; index++) {
            const originalText = currentSections[index]; // Get the original text for the current section
            const translationIndex = index + indexOfFirstSection; // Calculate the global index

            if (!translatedSections[translationIndex]) {
                let translatedText = '';

                // Check the translation memory if enabled
                if (useTranslationMemory) {
                    try {
                        const cachedTranslation = await axios.post('/api/translation-memory/check', {
                            userId: currentUserId,  // Include userId in the request
                            originalText,
                            sourceLanguage,
                            targetLanguage,
                        });

                        if (cachedTranslation.data.translatedText) {
                            translatedText = cachedTranslation.data.translatedText;
                            console.log(`Translation from memory for section ${index}: "${translatedText}"`);
                        }
                    } catch (err) {
                        console.error('Error checking translation memory:', err);
                    }
                }

                // If no translation from memory, use the translation API
                if (!translatedText) {
                    try {
                        const response = await axios.post(`/api/translate`, {
                            text: originalText,
                            sourceLanguage,
                            targetLanguage,
                            service: translationService,
                        }, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        });

                        translatedText = response.data.translatedText;
                        console.log(`Translation from API for section ${index}: "${translatedText}"`);

                        // Save to the translation memory if enabled
                        if (useTranslationMemory) {
                            try {
                                await axios.post('/api/translation-memory/save', {
                                    userId: currentUserId,  // Include userId in the request
                                    originalText,
                                    translatedText,
                                    sourceLanguage,
                                    targetLanguage,
                                });
                                console.log(`Saved translation to memory for section ${index}`);
                            } catch (err) {
                                console.error('Error saving translation to memory:', err);
                            }
                        }
                    } catch (err) {
                        console.error('Error translating text:', err);
                        alert('Error translating text.');
                        return; // Stop the process if an error occurs
                    }
                }

                // Update the translated sections state with the new translated text
                setTranslatedSections((prevTranslations) => {
                    const updatedTranslations = [...prevTranslations];
                    updatedTranslations[translationIndex] = translatedText;
                    return updatedTranslations;
                });
            }
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };


    useEffect(() => {
        // Play sound when a new message is received, but not for the current user's message
        const lastMessage = message[message.length - 1];
        if (lastMessage && lastMessage.senderId._id !== currentUserId) {
            audioRef.current.play(); // Play the notification sound
        }
    }, [message]);

    return (
        <Container maxWidth="lg" sx={{ marginTop: '50px' }}>
            {/* Source and Target Language Context */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Flag code={getCountryCodeForLanguage(sourceLanguage)} style={{ width: '40px', marginRight: '10px' }} />
                    <Typography variant="h6">Source Language: {sourceLanguage}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6">Target Language: {targetLanguage}</Typography>
                    <Flag code={getCountryCodeForLanguage(targetLanguage)} style={{ width: '40px', marginLeft: '10px' }} />
                </Box>
            </Box>

            {/* Only show if the user is the project creator */}
            {(typeof projectCreator === 'object' ? projectCreator._id : projectCreator?.toString()) === currentUserId?.toString() && (
                <>
                    {console.log("User is the project creator, showing additional features")}
                    <Box sx={{ marginBottom: '20px' }}>
                        <FormControl fullWidth>
                            <InputLabel>Select Translator</InputLabel>
                            <Select
                                value={assignedTo || 'unassign'} // Set to 'unassign' if no translator is assigned
                                onChange={handleTranslatorChange} // Handle the translator change
                                fullWidth
                            >
                                <MenuItem value="unassign">Unassign Translator</MenuItem> {/* Unassign option */}
                                {teamMembers.map((member) => (
                                    <MenuItem key={member.userId._id} value={member.userId._id}>
                                        {member.userId.email}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{ marginBottom: '20px' }}>
                        <FormControl fullWidth>
                            <InputLabel>Select Translation Service</InputLabel>
                            <Select value={translationService} onChange={(e) => setTranslationService(e.target.value)} fullWidth>
                                <MenuItem value="deepl">DeepL</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ marginBottom: '20px' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={useTranslationMemory}
                                    onChange={(e) => setUseTranslationMemory(e.target.checked)}  // Toggle translation memory usage
                                    color="primary"
                                />
                            }
                            label="Use Translation Memory"
                        />
                    </Box>

                    <Box sx={{ textAlign: 'right', marginBottom: '10px' }}>
                        <Button variant="contained" color="primary" onClick={handleAutoTranslate}>
                            Auto Translate
                        </Button>
                    </Box>
                </>
            )}

            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="h6">Total Words: {wordCount} | Total Characters: {charCount}</Typography>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, marginBottom: '20px' }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h6">Overall Progress: {overallProgress.toFixed(2)}%</Typography>
                    <LinearProgress variant="determinate" value={overallProgress} />
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h6">Page Progress: {pageProgress.toFixed(2)}%</Typography>
                    <LinearProgress variant="determinate" value={pageProgress} />
                </Box>
            </Box>

            <Grid container spacing={4}>
                {currentSections.map((section, index) => (
                    <Grid item xs={12} key={index}>
                        <Grid container spacing={2} alignItems="flex-start">
                            <Grid item xs={5}>
                                <TextField
                                    label="Original Text"
                                    multiline
                                    fullWidth
                                    value={section}
                                    InputProps={{ readOnly: true }}
                                    variant="outlined"
                                    sx={{ height: '100%' }}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    label="Translated Text"
                                    multiline
                                    fullWidth
                                    value={translatedSections[index + indexOfFirstSection] || ''}
                                    onChange={(e) => handleTranslationChange(index, e.target.value)}
                                    variant="outlined"
                                    sx={{ height: '100%', backgroundColor: approvedSections.includes(index + indexOfFirstSection) ? '#d4edda' : '' }}
                                    InputProps={{ readOnly: approvedSections.includes(index + indexOfFirstSection) }}
                                />
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: 'right' }}>
                                <IconButton
                                    color={approvedSections.includes(index + indexOfFirstSection) ? 'secondary' : 'primary'}
                                    onClick={() => toggleApproval(index)}
                                >
                                    {approvedSections.includes(index + indexOfFirstSection) ? <UndoIcon /> : <CheckCircleIcon />}
                                </IconButton>
                                {(typeof projectCreator === 'object' ? projectCreator._id : projectCreator?.toString()) === currentUserId?.toString() && (
                                    <Button variant="outlined" onClick={() => handleTranslate(index)} sx={{ marginTop: '10px' }}>
                                        Translate
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>

            <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
                <Pagination count={Math.ceil(filteredSections.length / sectionsPerPage)} page={currentPage} onChange={handlePageChange} />
            </Box>

            <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
                <Button variant="contained" color="primary" onClick={handleSaveTranslation}>
                    Save Translations
                </Button>
            </Box>

            {/* Chat Box */}
            {assignedTo ? (
                <Box sx={{ marginTop: '40px' }}>
                    <Typography variant="h6">Chat</Typography>
                    <Box
                        ref={chatBoxRef}
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '10px',
                            padding: '20px',
                            height: '300px',
                            overflowY: 'auto',
                            marginBottom: '20px',
                            scrollbarWidth: 'none',
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                        }}
                    >
                        {message.map((msg, index) => {
                            const isCurrentUser = msg.senderId._id === currentUserId;

                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: isCurrentUser ? 'flex-end' : 'flex-start',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {!isCurrentUser && (
                                        <Box sx={{ marginRight: '10px' }}>
                                            {msg.senderId.avatarUrl ? (
                                                <img
                                                    src={`http://192.168.68.130:5002${msg.senderId.avatarUrl}`}
                                                    alt="avatar"
                                                    style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                                                />
                                            ) : (
                                                <Box
                                                    sx={{
                                                        width: '40px',
                                                        height: '40px',
                                                        borderRadius: '50%',
                                                        backgroundColor: '#ccc',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        color: '#fff',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {msg.senderId.firstName.charAt(0)}
                                                    {msg.senderId.lastName.charAt(0)}
                                                </Box>
                                            )}
                                        </Box>
                                    )}

                                    <Box
                                        sx={{
                                            backgroundColor: isCurrentUser ? '#e0f7fa' : '#f5f5f5',
                                            borderRadius: '15px',
                                            padding: '10px',
                                            maxWidth: '70%',
                                        }}
                                    >
                                        <Typography>{msg.message}</Typography>
                                    </Box>

                                    {isCurrentUser && (
                                        <Box sx={{ marginLeft: '10px' }}>
                                            {msg.senderId.avatarUrl ? (
                                                <img
                                                    src={`http://192.168.68.130:5002${msg.senderId.avatarUrl}`}
                                                    alt="avatar"
                                                    style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                                                />
                                            ) : (
                                                <Box
                                                    sx={{
                                                        width: '40px',
                                                        height: '40px',
                                                        borderRadius: '50%',
                                                        backgroundColor: '#ccc',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        color: '#fff',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {msg.senderId.firstName.charAt(0)}
                                                    {msg.senderId.lastName.charAt(0)}
                                                </Box>
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            );
                        })}
                    </Box>

                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Type your message..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        color="primary"
                                        onClick={handleSendMessage}
                                    >
                                        <SendIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            ) : (
                <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
                    <Typography variant="h6" color="textSecondary">
                        Chat feature is not available for this project.
                    </Typography>
                </Box>
            )}
        </Container>
    );
};

export default TranslateProject;
