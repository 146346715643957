import React, { useState } from 'react';
import {
    Container, Button, Typography, Paper, Box, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, CircularProgress, Input, Alert
} from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';

const LanguageSettings = () => {
    const token = useSelector((state) => state.auth.token);
    const [message, setMessage] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);

    // Function to clear translation memory
    const handleClearMemory = async () => {
        try {
            await axios.delete('api/translation-memory/clear', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setMessage({ text: 'Translation memory cleared successfully.', type: 'success' });
        } catch (err) {
            console.error('Error clearing translation memory:', err);
            setMessage({ text: 'Error clearing translation memory.', type: 'error' });
        }
    };

    // Function to download translation memory in TMX format
    const handleDownloadMemory = async () => {
        setLoadingDownload(true);
        try {
            const response = await axios.get('api/translation-memory/download', {
                headers: { Authorization: `Bearer ${token}` },
                responseType: 'blob',
            });

            const blob = new Blob([response.data], { type: 'application/xml' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = 'translation_memory.tmx';
            link.click();
            window.URL.revokeObjectURL(downloadUrl);
            setMessage({ text: 'Download successful.', type: 'success' });
        } catch (err) {
            console.error('Error downloading translation memory:', err);
            setMessage({ text: 'Error downloading translation memory.', type: 'error' });
        } finally {
            setLoadingDownload(false);
        }
    };

    // Function to upload a TMX file
    const handleFileUpload = async () => {
        if (!file) {
            setMessage({ text: 'Please select a file to upload.', type: 'error' });
            return;
        }

        setUploading(true);
        const formData = new FormData();
        formData.append('file', file);

        try {
            await axios.post('/api/translation-memory/upload', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            setMessage({ text: 'Translation memory uploaded successfully.', type: 'success' });
        } catch (err) {
            console.error('Error uploading translation memory:', err);
            setMessage({ text: 'Error uploading translation memory.', type: 'error' });
        } finally {
            setUploading(false);
        }
    };

    // Open the confirmation dialog
    const openConfirmationDialog = () => {
        setOpenDialog(true);
    };

    // Close the confirmation dialog
    const closeConfirmationDialog = () => {
        setOpenDialog(false);
    };

    // Confirm clearing memory
    const confirmClearMemory = () => {
        handleClearMemory();
        closeConfirmationDialog();
    };

    return (
        <Container maxWidth="sm" sx={{ marginTop: '50px' }}>
            <Paper elevation={3} sx={{ padding: '30px', borderRadius: '10px' }}>
                <Typography variant="h4" gutterBottom>Language Settings</Typography>

                {/* Download Translation Memory Section */}
                <Box sx={{ marginTop: '20px' }}>
                    <Typography variant="h6">Download Translation Memory</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDownloadMemory}
                        disabled={loadingDownload}
                        sx={{ marginTop: '10px' }}
                        startIcon={loadingDownload && <CircularProgress size={24} />}
                    >
                        {loadingDownload ? 'Downloading...' : 'Download TMX File'}
                    </Button>
                </Box>

                {/* Upload Translation Memory Section */}
                <Box sx={{ marginTop: '40px' }}>
                    <Typography variant="h6">Upload Translation Memory (TMX)</Typography>
                    <Input
                        type="file"
                        inputProps={{ accept: '.tmx' }}
                        onChange={(e) => setFile(e.target.files[0])}
                        sx={{ marginBottom: '10px' }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFileUpload}
                        disabled={uploading}
                        startIcon={uploading && <CircularProgress size={24} />}
                    >
                        {uploading ? 'Uploading...' : 'Upload TMX File'}
                    </Button>
                </Box>

                {/* Clear Translation Memory Section */}
                <Box sx={{ marginTop: '40px' }}>
                    <Typography variant="h6">Clear Translation Memory</Typography>
                    <Button variant="contained" color="error" onClick={openConfirmationDialog} sx={{ marginTop: '10px' }}>
                        Clear Translation Memory
                    </Button>
                </Box>

                {/* Display success/error messages */}
                {message && (
                    <Alert
                        severity={message.type}
                        sx={{ marginTop: '20px' }}
                    >
                        {message.text}
                    </Alert>
                )}

                {/* Confirmation Dialog */}
                <Dialog open={openDialog} onClose={closeConfirmationDialog}>
                    <DialogTitle>Clear Translation Memory</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to clear your translation memory? This action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeConfirmationDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={confirmClearMemory} color="error">
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </Container>
    );
};

export default LanguageSettings;
